import { defineStore } from 'pinia';

export const useFiltersStore = defineStore('filters', {
    state: () => ({
        activeFilters: <Array<string>>[]
    }),

    actions: {
        setActiveFilters(value: Array<string>) {
            this.activeFilters = value;
        },
        getActiveFilters() {
            return this.activeFilters;
        }
    },
});
