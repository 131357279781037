import { defineStore } from 'pinia';

export const useFacetsStore = defineStore('facets', {
    state: () => ({
        availableFacets: <any>null,
        loadedStreamIds: <string[]>[]
    }),

    actions: {
        setAvailableFacets(value: any) {
            this.availableFacets = value;
        },
        getAvailableFacets() {
            return this.availableFacets;
        },
        addAvailableFacet(parent: string, child: string, amount: number) {
            if (!this.availableFacets) {
                return;
            }
            if (this.availableFacets.hasOwnProperty(parent)) {
                const storedAmount = this.availableFacets[parent][child] ?? 0;
                this.availableFacets[parent][child] = amount + storedAmount;
            } else {
                this.availableFacets[parent] = {};
                this.availableFacets[parent][child] = amount;
            }
        },
        getLoadedStreamIds() {
            return this.loadedStreamIds;
        },
        setLoadedStreamIds(streamIds: string[]) {
            this.loadedStreamIds = streamIds;
        },
        addStreamId(streamId:string) {
            this.loadedStreamIds.push(streamId);
        }
    },
});
